<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/projects"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.projectDetails.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="project">
      <ion-card>
        <ion-card-header>
          <ion-row class="ion-justify-content-between ion-align-items-center">
            <ion-col size="auto">
               <ion-thumbnail v-if="project.customer.logo">
                <img :src="project.customer.logo" />
              </ion-thumbnail>
              <ion-thumbnail v-else>
                <img :src="`${s3Url}/medias/images/icons/icon-logo.png`" />
              </ion-thumbnail>
            </ion-col>
            <ion-col>
              <ion-card-subtitle>
                {{ project.customer.name }}
              </ion-card-subtitle>
              <ion-card-title>{{ project.name }}</ion-card-title>
            </ion-col>
          </ion-row>
        </ion-card-header>
        <ion-card-content>
          <ODNBadges>
            <ODNBadge :color="project.projectStatus.color">
              {{ $t(`projectStatuses.${project.projectStatus.name}`) }}
            </ODNBadge>
            <ODNBadge>
              <ion-icon
                :icon="icons.ellipse"
                :style="{ color: project.hasSurveys ? '#2dd36f' : '#eb445a' }"
              />
              {{ $t('labels.hasSurveys') }}
            </ODNBadge>
            <ODNBadge>
              <ion-icon
                :icon="icons.ellipse"
                :style="{ color: project.hasTickets ? '#2dd36f' : '#eb445a' }"
              />
              {{ $t('labels.hasTickets') }}
            </ODNBadge>
          </ODNBadges>
          <div v-html="project.notes"></div>
        </ion-card-content>
      </ion-card>

      <ion-list lines="none">
        <!-- Surveys -->
        <ion-item
          v-if="project.hasSurveys"
          button
          :router-link="`/tabs/projects/${project.id}/surveys`"
        >
          <ion-icon :icon="icons.fileTrayFullOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.surveys') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="project.surveyCount > 0 ? 'primary' : null"
          >
            {{ project.surveyCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- Tickets -->
        <ion-item
          v-if="project.hasTickets"
          button
          :router-link="`/tabs/projects/${project.id}/tickets`"
        >
          <ion-icon :icon="icons.ticketOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.tickets') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="project.ticketCount > 0 ? 'primary' : null"
          >
            {{ project.ticketCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- Appointments -->
        <ion-item
          button
          :router-link="`/tabs/projects/${project.id}/appointments`"
        >
          <ion-icon :icon="icons.calendarOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.appointments') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="project.appointmentCount > 0 ? 'primary' : null"
          >
            {{ project.appointmentCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- Procedures -->
        <ion-item
          button
          :router-link="`/tabs/projects/${project.id}/procedures`"
        >
          <ion-icon :icon="icons.helpBuoyOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.procedures') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="project.procedureCount > 0 ? 'primary' : null"
          >
            {{ project.procedureCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- Stores -->
        <ion-item button :router-link="`/tabs/projects/${project.id}/stores`">
          <ion-icon :icon="icons.storefrontOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.stores') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="project.storeCount > 0 ? 'primary' : null"
          >
            {{ project.storeCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRow,
  IonCol,
  IonThumbnail,
  IonSpinner,
  IonIcon,
  IonNote,
  toastController,
} from '@ionic/vue';
import {
  fileTrayFullOutline,
  ticketOutline,
  calendarOutline,
  helpBuoyOutline,
  storefrontOutline,
  ellipse,
} from 'ionicons/icons';

import ODNBadges from '@c/odn-badges.vue';
import ODNBadge from '@c/odn-badge.vue';

import APIService from '@s/api.service';

export default {
  name: 'ProjectDetails',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonButtons,
    IonBackButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRow,
    IonCol,
    IonThumbnail,
    IonSpinner,
    IonIcon,
    IonNote,
    ODNBadges,
    ODNBadge,
  },
  data() {
    return {
      loading: true,
      icons: {
        fileTrayFullOutline,
        ticketOutline,
        calendarOutline,
        helpBuoyOutline,
        storefrontOutline,
        ellipse,
      },
    };
  },
  computed: {
    ...mapState('projects', {
      project: 'selectedProject',
    }),
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    }
  },
  async ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.loading = true;
  },
  methods: {
    ...mapActions('projects', ['selectProject']),
    async fetchData() {
      try {
        const response = await APIService.get(
          `/projects/${this.$route.params.id}`
        );
        this.selectProject(response.data);
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.project.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
